.album-header {
  padding: 16px;
  text-align: center;
}

.album-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 8px;
}

.album-info {
  color: darkgray;
}

.addresses {
  border: 1px solid black;
  border-radius: 8px;
  padding: 8px;
  margin-top: 16px;
}

.addr {
  font-size: 18px;
  font-weight: bold;
}

input.addr {
  border: 2px solid gray;
  max-width: 300px;
}
